export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '证书图片',
    prop: 'certUrl',
    type: 'upload',
    slot: true,
    listType: 'picture-img'
  }, {
    label: '证书项目名称',
    prop: 'certName'
  }, {
    label: '资质项目名称',
    prop: 'projectName'
  }, {
    width: 150,
    label: '获得状态',
    prop: 'projectStatus',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待获取',
        value: 0
      }, {
        label: '已获得',
        value: 1
      }
    ]
  }, {
    label: '备注',
    prop: 'remark'
  }
  ]
}
